import { Sidebar } from "../../components/Sidebar";
import { Card, Typography,Grid,Button } from "@mui/material";
import { Delete } from "@mui/icons-material";

export const Payment = () => {
  let CardData=[
    {
      name:'Visa Card'
    },
    {
      name:'Master Card'
    },
    {
      name:'Visa Card'
    },
    {
      name:'Master Card'
    },
  ]
  return (
    <>
      <Sidebar>
        <Typography variant='h3'>Payments</Typography>
        <Grid container spacing={7} sx={{p:4}}>
            {
            CardData.map((item)=><Grid item>
            <Card sx={{ boxShadow: 15, p: 2,borderRadius: 5 }}>
              <Typography sx={{ m: 1,display:'flex',justifyContent:'flex-end' }}><Delete></Delete></Typography>
              <Typography sx={{ mx: 4,my:4, fontSize: 35 }}>1111-2222-4444</Typography>
              <Grid container sx={{justifyContent:'space-between',mb:1}}>
                <Grid>{item.name}</Grid>
                <Grid>Exp Data 09/22</Grid>
              </Grid>
            </Card>
            </Grid>
            )
            }
        </Grid>

<Typography sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{px:5,mb:3}}>ADD CARD</Button>
</Typography>
      </Sidebar>
    </>
  );
};
