import { Grid, Container } from "@mui/material";
import { Line } from "react-chartjs-2";
import chartData from "./chartData.json";
import chartOptions from "./chartOptions.json";
import { TopCard } from "../../components/TopCard";
import { Sidebar } from "../../components/Sidebar";

export const Home = () => {
  return (
    <>
      <Sidebar>
        <Grid container spacing={4} textAlign="center">
          <Grid item md={4}>
            <TopCard heading="Users" text="40" />
          </Grid>
          <Grid item md={4}>
            <TopCard heading="Subscribed" text="20" />
          </Grid>
          <Grid item md={4}>
            <TopCard heading="Revenue" text="$ 45" />
          </Grid>
        </Grid>
        <Container maxWidth="md" sx={{ mt: 10 }}>
          <Line data={chartData} options={chartOptions} />
        </Container>
      </Sidebar>
    </>
  );
};
