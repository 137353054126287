import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBcrJ-1WsWyil6VO6QsYJgK3bLCqO5i4_s",
  authDomain: "let-s-love-right.firebaseapp.com",
  projectId: "let-s-love-right",
  storageBucket: "let-s-love-right.appspot.com",
  messagingSenderId: "841458618047",
  appId: "1:841458618047:web:68bcce385b64f040a2000a",
  measurementId: "G-2PP4XJF0HW",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
