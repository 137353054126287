import { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { TextField, Box, Button, Container } from "@mui/material";

export const Blogs = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [img, setImg] = useState("./images/placeholder.png");

  return (
    <>
      <Sidebar>
        <Container
          sx={{ display: "flex", justifyContent: "center", height: "100%" }}
        >
          <Box sx={{ width: "50%", textAlign: "center" }}>
            <form>
              <TextField
                label="Title"
                placeholder="Enter Your Title"
                type="text"
                autoFocus
                fullWidth
                sx={{
                  mt: 5,
                  mb: 5,
                }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <img src={img} alt="Image" style={{ width: "100%" }} />
              <Button variant="contained" color="secondary" fullWidth>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setImg(URL.createObjectURL(e.target.files[0]))
                  }
                />
              </Button>
              <TextField
                label="Blog Content"
                placeholder="Enter Blog Content Here"
                type="text"
                fullWidth
                multiline
                rows={4}
                sx={{
                  mt: 5,
                  mb: 5,
                }}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
              <Button variant="contained" color="primary" fullWidth>
                POST
              </Button>
            </form>
          </Box>
        </Container>
        <br />
      </Sidebar>
    </>
  );
};
