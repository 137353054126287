import { Box, Typography } from "@mui/material";

export const TopCard = ({ heading, text }) => {
  return (
    <Box sx={{ p: 2, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h4">
        <b>{heading}</b>
      </Typography>
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
};
