import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#8650CB",
    },
    secondary: {
      main: "#797979",
    },
  },
});
