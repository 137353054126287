import { Routes, Route } from "react-router-dom";
import { Home, Login, Users, Payment, Blogs } from "./pages";
import { Protect } from "./services/Protect";
import { CheckLogin } from "./services/CheckLogin";
import { CssBaseline } from "@mui/material";
import "./style.css";
import { Manage } from "./pages";

export const Router = () => {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route element={<CheckLogin />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<Protect />}>
          <Route path="home" element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="payment" element={<Payment />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="manage" element={<Manage />} />
        </Route>
      </Routes>
    </>
  );
};
