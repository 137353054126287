import { useState, useEffect } from "react";
import { GetCollection } from "../../firebase/getData";
import { Sidebar } from "../../components/Sidebar";
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { getAuth, deleteUser } from "firebase/auth";

export const Users = () => {
  const [data, setDate] = useState([]);

  useEffect(() => {
    GetCollection("users")
      .then((res) => setDate(res))
      .catch((err) => console.log(err));
  }, []);

  const deleteHandler = () => {
    const auth = getAuth();
    const user = auth.id;
    deleteUser(user)
      .then(() => {
        alert("Data Removed From Database");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Sidebar>
        {data.length === 0 ? (
          <Box
            sx={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>S.No.</b>
                </TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Education</b>
                </TableCell>
                <TableCell>
                  <b>City</b>
                </TableCell>
                <TableCell>
                  <b>Gender</b>
                </TableCell>
                <TableCell>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.education}</TableCell>
                      <TableCell>{item.city}</TableCell>
                      <TableCell>{item.gender}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteHandler()}>
                          <Delete />
                        </IconButton>
                        <IconButton
                          onClick={() => alert("Edit Button Clicked")}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </Sidebar>
    </>
  );
};
