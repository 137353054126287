import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export const GetCollection = async (collectionName) => {
  const arr = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

export const GetQueryData = async (collectionName, query) => {};
