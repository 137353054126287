import { Box, Grid, Container, Typography, IconButton } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { Sidebar } from "../../components/Sidebar";
import { TopCard } from "../../components/TopCard";
import doughnutOptions from "./doughnutOptions.json";
import chartOptions from "./chartOptions.json";
import chartData from "./chartData.json";
import { Line, Doughnut } from "react-chartjs-2";

export const Manage = () => {
  return (
    <>
      <Sidebar>
        <Grid container spacing={4} textAlign="center">
          <Grid item md={4}>
            <TopCard heading="Active Users" text="40 Logged In Users" />
          </Grid>
          <Grid item md={6}>
            <TopCard heading="Demographics" text="40% Male, 60% Female" />
          </Grid>
          <Grid item md={2}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <a href="#demographics">
                <IconButton>
                  <ArrowDownward />
                </IconButton>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Container maxWidth="md" sx={{ mt: 10 }}>
          <Line data={chartData} options={chartOptions} />
        </Container>
        <Container maxWidth="xs" sx={{ mt: 10 }} id="demographics">
          <Typography variant="h5">
            <b> END-USER DEMOGRAPHICS </b>
          </Typography>
          <br />
          <Doughnut data={doughnutOptions} />
        </Container>
      </Sidebar>
    </>
  );
};
